/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/*
  This is the top-level selector

  It should be relative positioned
  to allow the children to be positioned absolutely
  relative to this
 */
.Wallop {
  position: relative;
}

/*
  This element groups all the items, but not the buttons

  It's a recommendation but it's very likely
  you'll want to hide any overflow from the items
  Especially when doing animations such as scale
 */
.Wallop-list {
  position: relative;
  overflow: hidden;
}

/*
  This is the item element

  By default, they are all hidden and
  positioned absolute
  I recommend always having .Wallop-item--current
  in your markup by default (probably on the first element)
 */
.Wallop-item {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*
  This is the current item element

  All we do here, is make it visible again reset
  the position to static. Could also be relative
 */
.Wallop-item--current {
  visibility: visible;
  position: relative;
}
